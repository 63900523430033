.Container {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  /* padding: 0 1rem; */
  background-color: #fff;
}
.Container::-webkit-scrollbar {
  display: none;
}
.Header {
  /* width: 100%; */

  display: flex;
  justify-content: space-between;
  background-color: #a29f9f;
  align-items: center;
  color: #fff;
  height: 4rem;
  border-radius: 10px;
  margin: 1rem;
}
.HeaderText {
  width: 80%;
  display: flex;
  align-items: center;
  padding: 1rem;
  justify-content: flex-start;
}
.HeaderText p {
  margin: 0;
  font-size: 1.5rem;
}
.HeadButton {
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
}
.orgSection {
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
  /* height: 100%; */
  padding: 0 1rem;
  /* /* width: 100%;  */
}
.HeadButton button {
  background: #49618b;
  font-size: 1.25rem;
  outline: none;
  border-radius: 7px;
  border: none;
  padding: 0.5rem;
  color: #fff;
  width: 115px;
  cursor: pointer;
}
.Grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1rem;
  padding: 1rem;
  row-gap: 1rem;
}
.Cards {
  display: flex;
  background: #fff;
  /* width: 20rem; */
  align-items: center;
  justify-content: space-between;
  border-radius: 1.25rem;
  border: 2px solid #a39797;
  outline: none;
  /* padding: 0.5rem 0; */
  height: 8rem;
  flex-direction: column;
}
.Cards p {
  font-size: 1.25rem;
  color: #857073;
  /* cursor: pointer;
  transition: color 0.3s, background-color 0.3s; */
}
/* .Cards p:hover {
  color:#66cc00; 
} */
.cardTop {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
}
.cardBottom {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 50%;
  /* padding: 1rem; */
  height: 50%;
}
.viewButton {
  color: #b4b409;
}
.editButton {
  color: #0076de;
}
.deleteButton {
  color: red;
}
.iconContainer {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltipText {
  visibility: hidden;
  width: 75px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  /* padding: 5px; */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 1rem;
}

.iconContainer:hover .tooltipText {
  visibility: visible;
  opacity: 1;
}
.cardContent {
  width: 100%;
  align-items: center;
  margin-left: 2rem;
}
.cardContent p {
  font-size: 1.25rem;
  font-weight: 500;
}
.AddressSection {
  width: 30rem;
  position: absolute;
  top: 50%;
  left: 55%;
  z-index: 99999;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #fff;
  border-radius: 1rem;
  padding: 1.25rem;
  align-items: center;
}
.popupClose {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  font-size: 1.5rem;
}
.popupSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem 0;
  align-items: center;
  justify-content: center;
  height: 70%;
}
.popupSection input {
  font-family: "Poppins";
  width: 100%;
  font-size: 1.25rem;
  background-color: #fff;
  padding: 0.5rem 0;
  text-align: center;
  border-radius: 5px;
  margin: 0.5rem 0;
  border: 3px solid #696969c1;
}
.popupSection input:focus {
  outline: 3px solid #606e7a;
  border-color: transparent;
}
.popupButton {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 30%;
}
.create {
  width: 10rem;
  border-radius: 6px;
  background-color: #49618b;
  border: none;
  outline: none;
  font-size: 1.25rem;
  color: #fff;
  padding: 0.5rem;
}
.cancel {
  width: 10rem;
  border-radius: 6px;
  background-color: #7e3e3e;
  border: none;
  outline: none;
  font-size: 1.25rem;
  color: #fff;
  padding: 0.5rem;
}
.popupSection input::-webkit-input-placeholder {
  text-align: center;
  text-transform: uppercase;
}

.Overlay {
  background-color: #3e3e3e1a;
  height: 100vh;
  position: fixed;
  width: 100vw;
  z-index: 99;
  top: 0;
}
.deletePopup {
  width: 25rem;
  position: absolute;
  top: 50%;
  left: 55%;
  z-index: 99999;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #a29f9f;
  border-radius: 1rem;
  padding: 1rem;
  align-items: center;
}
.deleteClose {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  font-size: 1.5rem;
  color: #fff;
}
.deleteSection {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.popupText {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
}
.popupText p {
  font-size: 1.25rem;
  color: #fff;
}
.popupDelete {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 50%;
  margin: 1rem 0;
}
.orgDelete {
  background: #49618b;
  font-size: 1.25rem;
  outline: none;
  border-radius: 7px;
  border: none;
  padding: 0.5rem;
  color: #fff;
  width: 115px;
  cursor: pointer;
}
.orgDeleteCancel {
  background: #7e3e3e;
  font-size: 1.25rem;
  outline: none;
  border-radius: 7px;
  border: none;
  padding: 0.5rem;
  color: #fff;
  width: 115px;
  cursor: pointer;
}
