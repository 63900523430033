.Container {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Login {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}
.loginImage {
  width: 35%;
  height: 40rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loginImage img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.form {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  width: 25%;
  padding: 1rem;
  border-radius: 1rem;
  /* border: 2px solid #48419a; */
  background: #fff;
}
.logo {
  width: 15rem;
  margin: 1rem 0;
}
.logo img {
  width: 100%;
  height: 100%;
}

.loginForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 1rem 0;
  border-radius: 1rem;
  padding: 1rem 0;
}

.input {
  width: 95%;
  padding: 0.75rem 0;
  margin: 0.5rem 0;
  text-align: center;
  outline: none;
  font-size: 1.25rem;
  border-radius: 1rem;
  border: 2px solid #848484;
  color: rgb(143, 97, 97);
}
.loginForm input::placeholder {
  color: #2b3b64f0;
}
.password {
  width: 95%;
  padding: 0.75rem 0;
  margin: 0.5rem 0;
  outline: none;
  font-size: 1.25rem;
  border-radius: 1rem;
  border: 2px solid #848484;
  color: rgb(143, 97, 97);
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.password input::placeholder {
  color: #2b3b64f0;
}
.password input {
  outline: none;
  border: none;
  font-size: 1.25rem;
  text-align: center;
  width: 90%;
}
.password svg {
  font-size: 1.5rem;
  color: #2b3b64f0;
  margin: 0 0.5rem;
}
.loginForm button {
  border: none;
  font-size: 1.25rem;
  margin: 0.5rem 0;
  padding: 0.5rem;
  width: 120px;
  background: #2b3b64f0;
  border-radius: 6px;
  color: #fffffff0;
  /* font-weight: 600; */
  cursor: pointer;
}
.signUp {
  display: flex;
  align-items: center;
  justify-content: center;
}
.signUp p {
  font-size: 1.25rem;
  color: #2b3b64f0;
  margin: 0;
}
.signUp button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  color: blue;
  font-size: 1.25rem;
}
