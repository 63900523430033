.Container{
    width: 100%;
    height: 100vh;
    padding: 0 1rem;
}
.HomeContent{
  display: flex;
  align-items: center;
  height: 95vh;
  width: 100%;
  margin: 1rem 0;
  /* border: 2px solid #efefef; */
  background: #fff;
  box-shadow: 0 3px 8px #0000003d;
}
.HomeContent iframe{
    width: 100%;
    height: 100%;
}