.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
}
.Active {
  background: #f6e3e3;
}
.page {
  font-size: 1.25rem;
  color: #721e25;
  margin: 0 0.5rem;
  /* border: 2px solid #e9bdbd; */
  border-radius: 5px;
  padding: 0.25rem;
  width: 2rem;
  text-align: center;
  list-style: none;
}
.page a {
  cursor: pointer;
}
.previous {
  font-size: 1.25rem;
  color: #7a6750;
  margin: 0 0.5rem;
  /* border: 2px solid #827b7b; */
  border-radius: 5px;
  padding: 0.25rem;
  list-style: none;
}
.previous a {
  cursor: pointer;
}
.next {
  font-size: 1.25rem;
  color: #7a6750;
  margin: 0 0.5rem;
  /* border: 2px solid #827b7b; */
  border-radius: 5px;
  padding: 0.25rem;
  list-style: none;
}
.next a {
  cursor: pointer;
}
.paginationContainer{
  display: flex;
  align-items: center;
  padding: 4px 0;
}
.itemsPerPageDropdown{
  margin-left: 1rem;
}
.itemsPerPageDropdown select{
 border: 2px solid #dbdbdb;
 border-radius: 6px;
 font-size: 1rem;
 outline: none;
 margin: 0 6px;
}