.Container {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  /* background-color: rgb(241, 239, 239); */
}
.Container::-webkit-scrollbar {
  display: none;
}
.Header {
  display: flex;
  justify-content: space-between;
  background-color: #a29f9f;
  align-items: center;
  color: #fff;
  height: 4rem;
  border-radius: 10px;
  margin: 1rem;
}
.HeaderText {
  width: 80%;
  align-items: center;
  display: flex;
  padding: 1rem;
  justify-content: flex-start;
}
.HeaderText p {
  margin: 0;
  font-size: 1.5rem;
}
.HeadButton {
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
}
.HeadButton button {
  background: #49618b;
  font-size: 1.25rem;
  outline: none;
  border-radius: 7px;
  border: none;
  padding: 0.5rem;
  color: #fff;
  width: 150px;
  cursor: pointer;
}
.List {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1rem;
  margin: 1rem;
  padding: 1rem;
  row-gap: 1rem;
}
.pageContainer{
  display: flex;
  align-items: center;
  margin: 0 1rem;
  justify-content: center;
}
.userCards {
  display: flex;
  width: 22rem;
  height: 10rem;
  align-items: flex-start;
  justify-content: center;
  border: 2px solid #a4840d;
  padding: 1rem;
  border-radius: 15px;
  flex-direction: column;
}
.cardTop {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 75%;
}
.userImage {
  font-size: 2rem;
  color: #a52a60;
  display: flex;
  align-items: center;
  width: 30%;
  justify-content: center;
}
.userDetail {
  display: flex;
  flex-direction: column;
  width: 70%;
  align-items: flex-start;
  justify-content: center;
}
.userDetail h6 {
  font-size: 1.25rem;
  color: #6a2ba7;
  margin: 0;
}
.userDetail p {
  font-size: 1.25rem;
  /* margin: 20px 0; */
  color: #415b72;
  font-weight: bold;
}
.userDetail span {
  color: #06481a;
}
.cardBotton {
  /* font-size: 1.75rem;  */
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  /* margin: 0.5rem 0; */
  /* /* color: #88622d; */
}
.bottom_right button {
  border: none;
  background: #454545;
  font-size: 1rem;
  padding: 0.5rem;
  color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 130px;
}
.bottom_left {
  font-size: 1.5rem;
  color: #0076de;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
}
.bottom_left svg {
  margin: 0 0.5rem;
}
.deleteButton {
  color: red;
}
.forward {
  color: #e2ff8a;
}
/* CSS */
.iconContainer {
  position: relative;
  display: inline-block;
}

.tooltipText {
  visibility: hidden;
  width: 75px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  /* padding: 5px; */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 1rem;
}

.iconContainer:hover .tooltipText {
  visibility: visible;
  opacity: 1;
}

.Buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.Buttons button {
  font-size: 1rem;
  outline: none;
  border-radius: 5px;
  border: none;
  width: 4rem;
  padding: 4px 0;
  border: 1px solid;
  margin: 0 0.5rem;
}
.passwordInput {
  border: none;
  background-color: transparent;
  /* width: 100%; */
  text-align: center;
  font-size: 1rem;
}
.AddressSection {
  width: 25rem;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 99999;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.AddressSection form {
  background-color: #fff;
  padding: 2rem 2rem;
  border-radius: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 99;
}
.AddressSection form input {
  font-family: "Poppins";
  width: 100%;
  font-size: 1rem;
  background-color: #fff;
  padding: 5px 1px;
  text-align: center;
  border-radius: 5px;
  margin: 10px 0;
  border: 2px solid #2a2a2ac1;
  /* outline:.25rem solid #000; */
}
.AddressSection form input:focus {
  /* outline: 0.25rem solid #1b8fe7; */
  border-color: transparent;
}
.AddressSection form {
  align-items: center;
}
.AddressSection form button {
  width: 70%;
  border-radius: 50px;
  background-color: #3b82f6;
  border: none;
  outline: none;
  height: 2rem;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  margin-top: 1rem;
}
.AddressSection form input::-webkit-input-placeholder {
  text-align: center;
  text-transform: uppercase;
}

.Popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1a1e5d;
  border: 3px solid #949393;
  border-radius: 20px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 8rem;
  z-index: 99;
}
.popupText p {
  font-size: 1.25rem;
  text-align: center;
  color: #fff;
  font-weight: 500;
  line-height: 25px;
}
.PopupButton {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
}
.PopupButton button {
  margin: 0 0.5rem;
  padding: 0.5rem 1rem;
  background-color: transparent;
  border: 3px solid #fff;
  outline: none;
  width: 100%;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 10px;
}
.select_section {
  display: flex;
  align-items: center;
  /* width: 100%; */
  justify-content: space-around;
  padding: 2rem;
  border: 2px solid #8f8fa0;
  border-radius: 1rem;
  margin: 1rem;
}
.org_select {
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: center;
}
.org_select select {
  border: none;
  background: none;
  border-bottom: 2px solid #939393;
  padding: 1rem 0;
  width: 300px;
  outline: none;
  font-size: 1.25rem;
  color: #8f2f2f;
}
.club_select {
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: center;
}
.club_select select {
  border: none;
  background: none;
  border-bottom: 2px solid #939393;
  padding: 1rem 0;
  width: 300px;
  outline: none;
  font-size: 1.25rem;
  color: #8f2f2f;
}
.userManagement {
  display: flex;
  flex-direction: column;
  margin: 1.5em 0;
  padding: 1rem 0;
  align-items: flex-start;
}
.UserManagementHeader {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
}
.Search {
  display: flex;
  align-items: center;
  width: 50%;
  border-bottom: 2px solid #939393;
  line-height: 0;
  padding: 0 1rem;
  justify-content: space-between;
  font-size: 2rem;
  color: #35357f;
}
.Search input {
  border: none;
  background: no-repeat;
  outline: none;
  width: 100%;
  font-size: 1.5rem;
}
.Search input::-webkit-input-placeholder {
  color: #8f2f2f;
}
.buttonSection {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 30%;
}
.buttonSection button {
  border: none;
  color: #fff;
  padding: 0.75rem;
  width: 175px;
  font-size: 1.25rem;
  border-radius: 1rem;
  background-color: #1a1e5d;
}

.roleSelected {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-45%, -50%);
  display: flex;
  flex-direction: column;
  width: 75%;
  align-items: center;
  justify-content: flex-start;
  border: 2px solid #935d6e;
  padding: 1rem;
  height: 75%;
  background: #f3f3f3;
  border-radius: 10px;
  overflow-y: scroll;
}
.roleCLose {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  font-size: 1.5rem;
}
.roleHead {
  display: flex;
  margin: 1rem 0;
  border-bottom: 2px solid #5a6a7a;
  width: 50%;
  align-items: center;
  justify-content: center;
}
.roleHead h4 {
  font-size: 1.5rem;
  margin: 0;
  color: #dc5050;
}
.Change_role {
  border: none;
  background: #8c5a5a;
  font-size: 1.15rem;
  color: #fff;
  padding: 0.25rem;
  width: 150px;
  border-radius: 5px;
}
.userList {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.userList table {
  width: 100%;
  border: 1px solid #999696;
}
.userList th {
  background: #5481d0;
  color: #fff;
  width: 10rem;
  height: 3rem;
  border: 2px solid #999696;
  font-size: 1.35rem;
}
.userList td {
  text-align: center;
  font-size: 1.25rem;
  border: 1px solid #999696;
}
.mentorAdd {
  border: none;
  background: #8c5a5a;
  font-size: 1.15rem;
  color: #fff;
  padding: 0.25rem;
  width: 150px;
  border-radius: 5px;
}
.userList select {
  width: 90%;
  text-align: center;
  border: none;
  outline: none;
  font-size: 1.15rem;
  padding: 0.15rem;
  border-radius: 2rem;
}
.viewButton {
  border: none;
  background: #113b32;
  font-size: 1.15rem;
  color: #fff;
  padding: 0.35rem;
  width: 100px;
  border-radius: 5px;
}
.AssignedMentorList {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-45%, -50%);
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  justify-content: flex-start;
  border: 2px solid #757a92;
  padding: 1rem;
  height: 50%;
  background: #f3f3f3;
  border-radius: 10px;
}
.listClose {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  font-size: 1.5rem;
}
.listHead {
  display: flex;
  border-bottom: 2px solid #5a6a7a;
  width: 50%;
  align-items: center;
  justify-content: center;
  line-height: 0;
}
.listHead h4 {
  font-size: 1.5rem;
  color: #292972;
}
.allMentorList {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 1rem 0;
  height: 100%;
  border-radius: 5px;
}
.allMentorList table {
  width: 100%;
}
.allMentorList th {
  background: #737b89;
  color: #fff;
  width: 10rem;
  height: 3rem;
  /* border: 3px solid #5b6082; */
  font-size: 1.25rem;
}
.allMentorList td {
  text-align: center;
  font-size: 1.15rem;
  border: 2px solid #5b6082;
  padding: 0.5rem 0;
}
.allMentorList button {
  width: 135px;
  color: #fff;
  border: none;
  background: #84105d;
  font-size: 1rem;
  padding: 0.5rem;
  border-radius: 5px;
}
.allMentorList select {
  border: none;
  outline: none;
  background: transparent;
  font-size: 1.15rem;
  padding: 0.5rem 0;
  font-weight: 300;
}

.memberCreate {
  width: 30rem;
  position: absolute;
  top: 50%;
  left: 55%;
  z-index: 99999;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #fff;
  border-radius: 1rem;
  padding: 1.25rem;
  align-items: center;
}
.memberClose {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  font-size: 1.5rem;
}
.membersection {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem 0;
  align-items: center;
  justify-content: center;
  /* height: 70%; */
}
.membersection input {
  font-family: "Poppins";
  width: 100%;
  font-size: 1.25rem;
  padding: 0.5rem 0;
  text-align: center;
  border-radius: 5px;
  margin: 0.5rem 0;
  border: 3px solid #696969c1;
  outline: none;
  color: #3e4486;
}
.membersection input::-webkit-input-placeholder {
  text-align: center;
  text-transform: uppercase;
  font-size: 1.25rem;
  color: #3e4486;
}
.password {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-family: "Poppins";
  width: 100%;
  font-size: 1.25rem;
  padding: 0.5rem 0;
  text-align: center;
  border-radius: 5px;
  margin: 0.5rem 0;
  border: 3px solid #696969c1;
  outline: none;
}
.password input {
  border: none;
  outline: none;
  font-size: 1.5rem;
  text-align: center;
  font-family: "Poppins";
  width: 80%;
  padding: 0;
  margin: 0;
}
.password svg {
  font-size: 1.5rem;
  color: #3d5557;
}

.roleSelection {
  font-family: "Poppins";
  width: 100%;
  font-size: 1.25rem;
  padding: 0.5rem 0;
  text-align: center;
  border-radius: 5px;
  margin: 0.5rem 0;
  border: 3px solid #696969c1;
  outline: none;
  color: #3e4486;
  text-align: center;
  text-transform: uppercase;
}
.clubSelection {
  font-family: "Poppins";
  width: 100%;
  padding: 0.5rem 0;
  text-align: center;
  border-radius: 5px;
  margin: 0.5rem 0;
  border: 3px solid #696969c1;
  outline: none;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.clubButton {
  display: flex;
  align-items: center;
}
.clubButton p {
  margin: 0;
  font-size: 1.25rem;
  color: #3e4486;
}
.clubButton svg {
  margin: 0 1rem;
  font-size: 2rem;
  color: #121a21;
}
.clubCheck {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 25%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 85%;
  border: 3px solid #696969c1;
  border-radius: 15px;
  background: aliceblue;
  z-index: 222222;
}
.clubValue {
  display: flex;
  align-items: center;
  width: 100%;
}
.clubValue p {
  width: 50%;
  font-size: 1.15rem;
  color: #695656;
}
.clubValue input {
  width: 50%;
  margin: 0;
  transform: scale(1.5);
}
.createButton {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  /* height: 30%; */
  padding: 1rem;
}
.create {
  width: 10rem;
  border-radius: 6px;
  background-color: #49618b;
  border: none;
  outline: none;
  font-size: 1.25rem;
  color: #fff;
  padding: 0.5rem;
}
.cancel {
  width: 10rem;
  border-radius: 6px;
  background-color: #7e3e3e;
  border: none;
  outline: none;
  font-size: 1.25rem;
  color: #fff;
  padding: 0.5rem;
}
.errorMessages {
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
}
.errorValue p {
  font-size: 1.25rem;
  color: red;
  margin: 1px;
}
.Overlay {
  background-color: #3e3e3e1a;
  height: 100vh;
  position: fixed;
  width: 100vw;
  z-index: 99;
  top: 0;
}
.deletePopup {
  width: 25rem;
  position: absolute;
  top: 50%;
  left: 55%;
  z-index: 99999;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #a29f9f;
  border-radius: 1rem;
  padding: 1rem;
  align-items: center;
}
.deleteClose {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  font-size: 1.5rem;
  color: #fff;
}
.deleteSection {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.popupText {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
}
.popupText p {
  font-size: 1.25rem;
  color: #fff;
}
.popupDelete {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 50%;
  margin: 1rem 0;
}
.orgDelete {
  background: #49618b;
  font-size: 1.25rem;
  outline: none;
  border-radius: 7px;
  border: none;
  padding: 0.5rem;
  color: #fff;
  width: 115px;
  cursor: pointer;
}
.orgDeleteCancel {
  background: #7e3e3e;
  font-size: 1.25rem;
  outline: none;
  border-radius: 7px;
  border: none;
  padding: 0.5rem;
  color: #fff;
  width: 115px;
  cursor: pointer;
}
