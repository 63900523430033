.Sidebar {
  width: 250px;
  background-color: #ffffff;
  color: #fff;
  padding:0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-family: "Mukta", sans-serif;
  /* box-shadow: 15px 15px 30px #d0d0f5, -1px -15px 30px #ffffff; */
  border-right: 3px solid #dddcdc;
}
.Header {
  width: 100%;
  height: 25%;
  text-align: center;
  /* border: 3px solid #fff; */
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background: #fff;
  border-radius: 110px;
  /* margin: 1rem 0; */
  overflow: hidden;
}
.headerImage {
  width: 5rem;
  height: auto;
}
.headerImage img {
  width: 100%;
  height: 100%;
}
.adminName {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 5%;
  margin: 1rem 0;
  border-bottom: 2px solid;
}
.adminName p {
  margin: 0;
  font-size: 1.5rem;
  color: #16898d;
  border-bottom: 1px solid #a31a3a;
  width: 75%;
  text-align: center;
}
.Menu {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 60%;
  flex-direction: column;
  width: 100%;
  margin: 0.5rem 0;
  color: #48419a;
}
.menuIcons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0.75rem 0;
}
.menuIcons svg {
  font-size: 1.5rem;
  margin: 0 0.5rem;
}
.menuIcons p {
  font-size: 1.2rem;
  margin: 0;
  cursor: pointer;
}
.selected {
  /* background: #7388a1; */
  border: 2px solid #7388a1;
  border-radius: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem 0;
  /* color: #fff; */
}
.selected svg {
  font-size: 1.5rem;
  margin: 0 0.5rem;
}
.selected p {
  font-size: 1.2rem;
  margin: 0;
  cursor: pointer;
}
.Popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #eeeded;
  border: 2px solid #b7b6b6;
  border-radius: 10px;
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 25rem;
  height: 12rem;
  color: #141272;
  z-index: 999;
}
.popupText p {
  font-size: 1.25rem;
  color:#3e4486;
  text-align: center;
}
.PopupButton {
  display: flex;
  align-items: center;
  height: 50%;
  width: 70%;
  justify-content: space-between;
}
.YesButton{
  background: #49618b;
  font-size: 1.25rem;
  outline: none;
  border-radius: 7px;
  border: none;
  padding: 0.3rem 2rem;
  color: #fff;
  cursor: pointer;
  margin: 0 0.5rem;
}
.NOButton{
  background: #7e3e3e;
  font-size: 1.25rem;
  outline: none;
  border-radius: 7px;
  border: none;
  padding: 0.3rem 2rem;
  color: #fff;
  cursor: pointer;
  margin: 0 0.5rem;
}
.popupClose {
  display: flex;
  width: 100%;
  font-size: 1.25rem;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}
.popupSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
}
.popupText {
  display: flex;
  align-items: center;
  height: 50%;
}
.logoutOverlay {
  width: 100%;
  height: 100vh;
  position: absolute;
  background-color: transparent;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  z-index: 999;
  background-color: #4140404a;
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%;
  }
}

.Footer{
  width: 100%;
  height: 20%;
  text-align: center;
  /* border: 3px solid #fff; */
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: normal;
  background: #ffffff;
  /* margin: 1rem 0; */
  /* overflow: hidden; */
  
}
.Footertext p{
  color: #48419a;
  border-bottom: 1px solid #a31a3a;
  font-size: 1rem;
}
.FooterImage {
  width: 5rem;
  height: auto;
}
.FooterImage img {
  width: 100%;
  height: 100%;
}
